<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import useProducts from "../../../hooks/useProducts";
import { onBeforeMount, ref, watch } from "vue";
import { useRoute } from "vue-router/auto";
import ProductLayout from "../../../layouts/ProductLayout.vue";
import { setPageTitle } from "../../../utils/pageTitle";
import { useThemeStore } from "@/stores/themeStore";
import {
  connectToServerApi
} from "@/utils/agGridUtils";
import Warehouses from '@/components/common/Warehouses.vue';

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const {
    fetchProduct,
    product
} = useProducts();

const route = useRoute();
const themeStore = useThemeStore();

let gridApi;
const context = ref(null);

const DAMAGED_REPORTS_URL = `/products/${route.params.id}/damage_reports`;
const PRODUCT_EXTERNAL_FILTERS_KEY = "products-external-filter";

onBeforeMount(async () => {
    await fetchProduct(route.params.id);
    setPageTitle(`Product -${product.value.id}`);
});

const onGridReady = async (params) => {

    gridApi = params.api;

    context.value = {
        apiUrl:DAMAGED_REPORTS_URL,
        filterKey: PRODUCT_EXTERNAL_FILTERS_KEY,
        advancedFilterKeys:[]
    };

    gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const columnDefs = [
    { field: "id", headerName: "Reports ID#", cellDataType: "text" },
    { field: "qty_damaged", headerName: "Quantity", cellDataType: "text" },
    { field: "", headerName: "Damaged By", cellDataType: "text" },
];

const defaultColDef = {
    sortable: true,
    suppressHeaderMenuButton: true,
    width: 120,
};

const gridOptions = {
    rowModelType: "serverSide",
    autoSizeStrategy: {
        type: "fitGridWidth",
        defaultMinWidth: 100,
    }
}

const warehouseFiltersChangedCallback = () => {
  gridApi.onFilterChanged();
}

</script>

<template>
    <div id="grid-wrapper">
        <Warehouses class="mb-6" :callBack="warehouseFiltersChangedCallback" :parent-filter="PRODUCT_EXTERNAL_FILTERS_KEY" />
        <ProductLayout :product="product">
            <div class="ml-4 mb-4">
                <h4 class="text-center">Damage Reports</h4>
            </div>
            <div class="damage-report-table d-flex">
                <AgGridVue style="width: 100%; height: 100%" :class="themeStore.agTheme" :columnDefs="columnDefs"
                    :defaultColDef="defaultColDef" :gridOptions="gridOptions" @grid-ready="onGridReady" :context="context">
                </AgGridVue>
            </div>
        </ProductLayout>
    </div>
</template>

<style>
.damage-report-table {
    height: 600px;
    padding-left: 10px;
    padding-right: 10px;
}
</style>
